import React from 'react'
import "./Contact.css"
import stepth from "../../assets/images/steph.jpeg"
import { FiGithub, FiMail, FiMap, FiPhoneCall, FiUser } from 'react-icons/fi'

const Contact = () => {
    return (
        <div className='contact' id="contacts" data-aos="zoom-in-up">
            <div className='card'>
                <div className='overPlay'></div>
                <div className='content'>
                    <h2>Contactez-moi</h2>
                    <div className='item'>
                        <div className='svg'>
                            <FiUser />
                        </div>
                        <div className='itemChild'>
                            <span className='title'>Noms</span>
                            <span>Stéphane Kikoni Zeus</span>
                        </div>
                    </div>

                    <div className='item'>
                        <div className='svg'>
                            <FiMap />
                        </div>
                        <div className='itemChild'>
                            <span className='title'>Adresse</span>
                            <span>Kinshasa, RDC</span>
                        </div>
                    </div>

                    <div className='item'>
                        <div className='svg'>
                            <FiPhoneCall />
                        </div>
                        <div className='itemChild'>
                            <span className='title'>Téléphone</span>
                            <span>+24381 7247035</span>
                        </div>
                    </div>

                    <div className='item'>
                        <div className='svg'>
                            <FiMail />
                        </div>
                        <div className='itemChild'>
                            <span className='title'>Email</span>
                            <span>kikonistephane@gmail.com</span>
                        </div>
                    </div>

                    <div className='item'>
                        <div className='svg'>
                            <FiGithub />
                        </div>
                        <div className='itemChild'>
                            <span className='title'>Github</span>
                            <span>StephaneCash</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className='card'>
                <img src={stepth} alt="" />
            </div>
        </div>
    )
}

export default Contact