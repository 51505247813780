import React from 'react'
import "./Footer.css"

const Footer = () => {

    const date = new Date();
    const an  = date.getFullYear();

    return (
        <div className='footer'>
            <div className='content1'>
                <div className='image'>
                    <h1>S</h1>
                </div>
                <p>
                    Seuls les braves méritent ce qui <br/> appartient aux braves
                </p>
            </div>

            <div className='content2'>
                <h6>Kikoni Stéphane Zeus</h6>
                &copy; {an} Tout droit réservé 
            </div>
        </div>
    )
}

export default Footer