import React from 'react'
import "./Cv.css"
import { FiDownload } from 'react-icons/fi'
import cv from "../../assets/doc/KIKONI_STEPHANE_CV.pdf"

const Cv = () => {
    return (
        <div className='cv' data-aos="fade-up">
            <div className='overPlay'>
                <a className='btn' href={cv} download>
                    <span>Télécharger mon CV</span>
                    <FiDownload />
                </a>
            </div>
        </div>
    )
}

export default Cv