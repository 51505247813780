import React from 'react'
import "./About.css"
import frontend from "../../assets/images/frontend.png"
import backend from "../../assets/images/backend.jpg"
import mobile from "../../assets/images/mobile.jpg"
import devops from "../../assets/images/devops.png"

const About = () => {
    return (
        <div className='about' id="about">
            <div className='text'>
                <h2>Salut, je m'appelle Stéphane Kikoni. Ravi de vous rencontrer.</h2>
                <div className='desc'>
                    Depuis le début de mon parcours en tant que ingénieur logiciel il y a plus de 5 ans, j'ai travaillé à distance pour des agences,
                    consulté pour des startups et collaboré avec des personnes talentueuses pour créer des produits numériques destinés à la fois aux entreprises et aux consommateurs. Je suis tranquillement confiant, naturellement curieux et
                    je travaille perpétuellement à l'amélioration de mes capacités.
                </div>
            </div>

            <div className='cards' data-aos="fade-up">
                <div className='card'>
                    <div className='image'>
                        <img src={frontend} alt="" />
                    </div>
                    <h4>Frontend</h4>

                    <p>
                        J'aime coder des choses à partir de zéro et j'aime donner vie aux idées dans le navigateur.
                    </p>

                    <h5>Langages et frameworks</h5>
                    <ul className='ul1'>
                        <li>HTML</li>
                        <li>CSS</li>
                        <li>Javascript</li>
                        <li>Sass</li>
                        <li>React</li>
                    </ul>

                    <h5>Outils de développement</h5>
                    <ul className='ul2'>
                        <li>VS Code </li>
                        <li>Bitbucket </li>
                        <li>Github</li>
                        <li>Jira</li>
                        <li>Bulma</li>
                    </ul>
                </div>

                <div className='card'>
                    <div className='image'>
                        <img src={backend} alt="" />
                    </div>
                    <h4>Backend</h4>

                    <p>
                        Le backend reste une des parties du développement que suis un vrai fan. Elle sépare l'application et la rend facile à maintenir.
                    </p>

                    <h5>Langages et frameworks</h5>
                    <ul className='ul1'>
                        <li>Javascript </li>
                        <li>JAVA </li>
                        <li>Spring Boot</li>
                        <li>PHP </li>
                        <li>Laravel</li>
                        <li>Node js</li>
                        <li>Typescript</li>
                    </ul>

                    <h5>Outils de développement</h5>
                    <ul className='ul2'>
                        <li>VS Code </li>
                        <li>Bitbucket </li>
                        <li>Github</li>
                        <li>Jira</li>
                    </ul>
                </div>

                <div className='card'>
                    <div className='image'>
                        <img src={mobile} alt="" />
                    </div>
                    <h4>Mobile</h4>

                    <p>
                        Le mobile fait de moi un vrai fullstack, j'aime cet aspect car ça me permet d'être un peu partout.
                    </p>

                    <h5>Langages et frameworks</h5>
                    <ul className='ul1'>
                        <li>Javascript </li>
                        <li>React native </li>
                        <li>Typescript</li>
                    </ul>

                    <h5>Outils de développement</h5>
                    <ul className='ul2'>
                        <li>VS Code </li>
                        <li>Bitbucket </li>
                        <li>Github</li>
                        <li>Jira</li>
                        <li>Android Studio</li>
                    </ul>
                </div>

                <div className='card'>
                    <div className='image'>
                        <img src={devops} alt="" />
                    </div>
                    <h4>DevOps</h4>

                    <p>
                        On peut bien développer mais si c'est rien n'est déployer alors rien ne sert à rien. Cette partie m'a permis à
                        expérimenter le comportement de  différents apps niveau serveurs.
                    </p>

                    <h5>Outils</h5>
                    <ul className='ul1'>
                        <li>Docker </li>
                        <li>Jenkins </li>
                        <li>GitLab</li>
                        <li>Nginx</li>
                        <li>PM2</li>
                        <li>VPS</li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default About