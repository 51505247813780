import React, { useState } from 'react'
import "./Projects.css"
import congomart from "../../assets/images/congomart.png";
import innoverce from "../../assets/images/innoverce.png"
import ligablo from "../../assets/images/ligablo.png"
import opale from "../../assets/images/opale.png"
import { FiGlobe } from "react-icons/fi"

const Projects = () => {

    const [onMouse, setOnMouse] = useState(0);

    function handleClic(val) {
        if (val === 1)
            window.open('https://congomart.com');
        if (val === 2)
            window.open('https://moneya.com');
        if (val === 3)
            window.open('https://ligablo.co');
        if (val === 4)
            window.open('https://opale-tech.com');
        if (val === 5)
            window.open('https://github.com/StephaneCash');
    }

    return (
        <div className='projects' id="projects">
            <div className='title'>
                <h2>Mon travail récent</h2>
                <p>Voici quelques projets de conception antérieurs sur lesquels j'ai travaillé.</p>
            </div>

            <div className='grille'>
                <div className='card' data-aos="fade-up" onMouseMove={() => setOnMouse(1)} onMouseOut={() => setOnMouse(0)}>
                    <div className='overPlay'></div>
                    <img src={congomart} alt="" />
                    <div className={onMouse === 1 ? "desc visible" : "desc"}>
                        <h6>Congo mart</h6>
                        <p>Est une plateforme E-commerce permettant aux revendeurs de créer <br /> leurs propres boutiques et de vendre.</p>
                        <button onClick={() => handleClic(1)} className='btn btn-primary'>En savoir plus</button>
                    </div>
                </div>

                <div className='card' data-aos="fade-up" onMouseMove={() => setOnMouse(2)} onMouseOut={() => setOnMouse(0)}>
                    <div className='overPlay'></div>
                    <img src={innoverce} alt="" />
                    <div className={onMouse === 2 ? "desc visible" : "desc"}>
                        <h6>Innoverce</h6>
                        <p>Est une plateforme fintech <br /> destinée aux transactions financières.</p>
                        <button onClick={() => handleClic(2)} className='btn btn-primary'>En savoir plus</button>
                    </div>
                </div>

                <div className='card' data-aos="fade-up" onMouseMove={() => setOnMouse(3)} onMouseOut={() => setOnMouse(0)}>
                    <div className='overPlay'></div>
                    <img src={ligablo} alt="" />
                    <div className={onMouse === 3 ? "desc visible" : "desc"}>
                        <h6>Ligablo</h6>
                        <p>Est un média en ligne <br /> permettant à la publication d'actualités en temps réel.</p>
                        <button onClick={() => handleClic(3)} className='btn btn-primary'>En savoir plus</button>
                    </div>
                </div>

                <div className='card' data-aos="fade-up" onMouseMove={() => setOnMouse(4)} onMouseOut={() => setOnMouse(0)}>
                    <div className='overPlay'></div>
                    <img src={opale} alt="" />
                    <div className={onMouse === 4 ? "desc visible" : "desc"}>
                        <h6>Opale</h6>
                        <p>Est une plateforme <br /> permettant à l'entreprise d'exposer leurs produits orientés affichage dynamique.</p>
                        <button onClick={() => handleClic(4)} className='btn btn-primary'>En savoir plus</button>
                    </div>
                </div>
            </div>

            <div className='buttonContent'>
                <button className='btn moreprojects' onClick={() => handleClic(5)}>
                    <FiGlobe size={20} />
                    <span>Plus de projects...</span>
                </button>
            </div>
        </div>
    )
}

export default Projects