import React from 'react'
import "./Acceuil.css"

const Acceuil = () => {
    return (
        <div className='accueil'>
            <div className='overPlay'></div>
            <div className='text'>
                <h1>
                  Ingénieur logiciel orineté  dévéloppement Frontend, <br/> Backend, Mobile, DevOps </h1>
                <p>Je conçois et code des systèmes informatiques magnifiquement simples et j’aime ce que je fais. </p>
            </div>
        </div>
    )
}

export default Acceuil