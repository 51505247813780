import React from 'react'
import "./Header.css"

const Header = () => {
    return (
        <div className='header'>
            <div className='image'>
                <h1>S</h1>
            </div>
            <ul>
                <li>
                    <a href="#about">A propos</a>
                </li>
                <li>
                    <a href="#projects">Projets</a>
                </li>
                <li>
                    <a href="#contacts">Contacts</a>
                </li>
            </ul>
        </div>
    )
}

export default Header