import React, { useEffect } from 'react'
import Header from '../components/header/Header'
import "./Main.css"
import Acceuil from '../components/accueil/Acceuil'
import About from '../components/about/About'
import Projects from '../components/projects/Projects'
import Contact from '../components/contacts/Contact'
import Cv from '../components/cv/Cv'
import Footer from '../components/footer/Footer'

import 'aos/dist/aos.css'
import AOS from 'aos';

const Main = () => {

    useEffect(() => {
        AOS.init({
            duration: 1000
        })
    }, []);

    return (
        <div className='mainApp'>
            <Header />
            <Acceuil/>
            <About />
            <Projects/>
            <Contact/>
            <Cv/>
            <Footer/>
        </div>
    )
}

export default Main